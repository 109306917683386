<template>
  <div class="service-form-part">
    <ServiceTypeField
      :choices="serviceTypes"
      v-on="$listeners"
      @result="setServiceForType($event.value[0])"
    />

    <ServiceField ref="service" hidden v-on="$listeners" />
  </div>
</template>

<script>
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField'
import ServiceTypeField from 'chimera/all/components/form/fields/serviceType/ServiceTypeField'
import { Selectable } from 'chimera/all/components/models/Selectable'
import {
  solarPanelsPurchaseConsumerES,
  solarPanelsPurchaseCorporateES
} from 'chimera/solarPanels/service'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceField,
    ServiceTypeField
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      serviceTypes: [
        new Selectable('Casa', 'Casa', solarPanelsPurchaseConsumerES.id),
        new Selectable('Empresa', 'Empresa', solarPanelsPurchaseCorporateES.id),
        new Selectable(
          'Negocio agrario',
          'Negocio agrario',
          solarPanelsPurchaseCorporateES.id
        ),
        new Selectable(
          'Gran proyecto empresarial',
          'Gran proyecto empresarial',
          solarPanelsPurchaseCorporateES.id
        )
      ]
    }
  },

  methods: {
    /**
     * @param {object}  type
     */
    setServiceForType (type) {
      if (!type) {
        return
      }

      const serviceType = this.serviceTypes.find(
        serviceType => serviceType.id === type.id
      )
      this.$refs.service.setValue(serviceType.value)
    }
  }
}
</script>
