<template>
  <FormModal
    title="Tipo de tejado"
    show-back-btn
  >
    <template #body>
      <RoofTypeElectricityEstimationFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import RoofTypeElectricityEstimationFormStep from '~/components/form/steps/roofTypeElectricityEstimation/RoofTypeElectricityEstimationFormStep'

export default {
  components: {
    RoofTypeElectricityEstimationFormStep,
    FormModal
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data () {
    return {
      headTitle: 'Tipo de tejado y estimación del consumo energético anual',
      headDescription: 'Indique el tipo de tejado y su estimación de consumo energético anual.',
      path: '/solicitudes-de-cotizacion/tipo-tejado-consumo-energetico-anual-estimado',
      progressValue: 50,
      checkoutStep: 2
    }
  }
}
</script>
